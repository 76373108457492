import { AntDesign } from "@expo/vector-icons";
import { useFocusEffect, useIsFocused, useNavigation } from "@react-navigation/native";
import { isNil } from "lodash";
import React, { memo, useCallback } from "react";
import { Platform, TouchableOpacity } from "react-native";
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

export const SessionFabAdd = memo(({ navigateToAddSessionScreen }) => {
  const isFocused = useIsFocused();
  const randomBottom = useSharedValue(-20);
  const navigation = useNavigation();

  const config = {
    duration: 500,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const style = useAnimatedStyle(() => {
    return {
      bottom: withTiming(randomBottom.value, config),
    };
  });

  useFocusEffect(
    useCallback(() => {
      randomBottom.value = 20;

      return () => {
        randomBottom.value = -20;
      };
    }, [])
  );

  if (!isFocused) return <></>;

  if (Platform.OS === "web")
    return (
      <TouchableOpacity
        testID="add-session-fab"
        onPress={
          !isNil(navigateToAddSessionScreen) ? navigateToAddSessionScreen : () => navigation.navigate("SessionAdd")
        }
      >
        <AntDesign style={{ marginRight: 20 }} name="pluscircle" size={28} color="orange" />
      </TouchableOpacity>
    );

  return (
    <Animated.View style={[{ position: "absolute", bottom: 0, right: 20 }, style]}>
      <AntDesign name="pluscircle" size={36} color="orange" onPress={navigateToAddSessionScreen} />
    </Animated.View>
  );
});
