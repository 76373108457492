import { Tag, Flex, Text, View, WhiteSpace, WingBlank, Icon } from "@ant-design/react-native";
import React, { memo, useContext, useState } from "react";
import { includes } from "lodash";
import { List } from "@ant-design/react-native";

import UserAvatar from "react-native-user-avatar";
import { Platform } from "react-native";
import { AuthenticatedUserContext } from "../../providers";
import { t } from "../../components";

export const StudentItemSelectable = memo(({ item, avatarColor, onPress }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { user } = useContext(AuthenticatedUserContext);

  return (
    <List.Item
      thumb={
        <UserAvatar
          name={item.name
            .split(" ")
            .map((n) => n[0])
            .join("")
            .toUpperCase()}
          bgColor={avatarColor ? avatarColor : isChecked ? "orange" : "#d1d1d1"}
        />
      }
      onPress={() => {
        setIsChecked((prev) => !prev);
        onPress();
      }}
    >
      <View
        testID="student-item"
        style={{ paddingTop: 2, paddingRight: 5, paddingBottom: 2, paddingLeft: 4, width: "100%" }}
      >
        <Flex justify={"between"} align="center">
          <View w="100%">
            <Flex justify="start">
              <WingBlank size="md" />
              <Flex
                direction="column"
                justify="center"
                align="start"
                style={{ width: Platform.OS === "web" ? "100%" : "90%" }}
              >
                <Text style={{ fontWeight: "bold" }} numberOfLines={1} testID="student-name">
                  {item.name}
                  {user.uid === item.key && ` ${t("you")}`}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{ width: Platform.OS === "web" ? "100%" : "90%" }}
                  testID="student-group"
                >
                  {includes(item.group.trim(), "\\") ? item.group.split("\\").join(" | ") : item.group.trim()}
                </Text>
              </Flex>
            </Flex>
          </View>
          <WhiteSpace size="sm" />
        </Flex>
      </View>
    </List.Item>
  );
});
