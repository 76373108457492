import { Provider } from "@ant-design/react-native";
import "expo-dev-client";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import React, { useContext } from "react";
import ErrorBoundary from "react-native-error-boundary";
import { enableScreens } from "react-native-screens";
import RootNavigator from "./components/RootNavigator";
import { AuthenticatedUserContext, AuthenticatedUserProvider } from "./providers";
import ErrorBoundaryScreen from "./screens/ErrorBoundary/ErrorBoundaryScreen";

// Define the config
SplashScreen.preventAutoHideAsync();
enableScreens();

const brandPrimary = "#274A6B";
const brandPrimaryTap = "#455e7f";

const theme = {
  color_text_base: "#000",

  primary_button_fill: brandPrimary,
  primary_button_fill_tap: brandPrimaryTap,

  color_link: brandPrimary,

  brand_primary: brandPrimary,
  brand_primary_tap: brandPrimaryTap,

  ghost_button_color: brandPrimary,
  ghost_button_fill_tap: `${brandPrimary}99`,

  tabs_color: brandPrimary,

  segmented_control_color: brandPrimary,
  segmented_control_fill_tap: `${brandPrimary}10`,

  input_color_icon_tap: brandPrimary,

  switch_fill: brandPrimary,
};

export default function App() {
  // ignore timer warnings
  // LogBox.ignoreLogs(["Setting a timer", "The contrast ratio"]);
  const { user } = useContext(AuthenticatedUserContext);

  let [fontsLoaded] = useFonts({
    antoutline: require("@ant-design/icons-react-native/fonts/antoutline.ttf"),
    antfill: require("@ant-design/icons-react-native/fonts/antfill.ttf"),
  });

  if (!fontsLoaded) {
    return null;
  }

  SplashScreen.hideAsync();

  return (
    <AuthenticatedUserProvider>
      <Provider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorBoundaryScreen}>
          <RootNavigator />
        </ErrorBoundary>
      </Provider>
    </AuthenticatedUserProvider>
  );
}
