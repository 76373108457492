import { Icon, SwipeAction, Toast } from "@ant-design/react-native";
import { useNavigation } from "@react-navigation/native";
import React, { memo, useCallback, useEffect, useState } from "react";
import { FlatList, Platform, RefreshControl } from "react-native";
import { getColor } from "..";
import EmptyList from "../../screens/SessionStack/SessionStudentsScreenComponents/EmptyList.jsx";
import { StudentItem } from "./StudentItem.jsx";
import { find, isNil } from "lodash";
import { t } from "../../components";
import { useTestSessionsContext } from "../../providers/SettingsProvider";

export const StudentsList = memo(
  ({ currentStudentsList, deleteStudent, sessionStats, session, groupList, navigate }) => {
    const navigation = useNavigation();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);
    const [swipeableState, setSwipeableState] = useState(false);

    const {
      sessionsData: { sessions },
    } = useTestSessionsContext();

    const handleDeleteStudent = (item) => {
      deleteStudent(item);
    };

    const navigateToUserEvaluation = (item) => {
      const webOptions =
        Platform.OS === "web"
          ? {
              headerLeft: () => <></>,
            }
          : {};

      if (!isNil(session.enabled) && session.enabled)
        navigation.navigate("SessionUserEvaluation", {
          student: item,
          session,
          ...webOptions,
        });
      else
        Toast.fail({
          duration: 3,
          content: t("sessions.haveToBeEnabled"),
        });
    };

    const renderItem = useCallback(
      ({ item, index }) => {
        return (
          <SwipeAction
            key={index}
            onSwipeableLeftWillOpen={() => setSwipeableState(true)}
            onSwipeableClose={() => setSwipeableState(false)}
            onSwipeableWillClose={() => setSwipeableState(false)}
            left={[
              {
                text: <Icon name="delete" size="sm" />,
                onPress: () => handleDeleteStudent(item),
                backgroundColor: "red",
                color: "white",
              },
            ]}
          >
            <StudentItem
              item={item}
              avatarColor={getColor(item.group, groupList)}
              navigate={navigate}
              sessionStats={sessionStats}
              session={find(sessions, ({ id }) => id === (session?.id ?? session?.key))}
              onPress={navigateToUserEvaluation}
              isPressDisabled={swipeableState}
            />
          </SwipeAction>
        );
      },
      [session, sessionStats, swipeableState, data]
    );

    useEffect(() => {
      if (currentStudentsList !== data) {
        setData(currentStudentsList);
      }
      if (loading) {
        setLoading(false);
      }
    }, [loading, currentStudentsList, sessionStats, session]);

    return (
      <FlatList
        data={data}
        refreshing={loading}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={() => data} />}
        ListEmptyComponent={!loading ? <EmptyList /> : <></>}
        contentContainerStyle={{ paddingBottom: 280 }}
        renderItem={renderItem}
        initialNumToRender={11}
      />
    );
  }
);
