import React, { useState, useEffect } from "react";

import UserAvatar from "react-native-user-avatar";

import { ActivityIndicator } from "@ant-design/react-native";

export const Avatar = ({
  fallbackText = "",
  onTouchEnd,
  size = "sm",
  bgColor = "lightBlue.300",
  uid,
  getAvatarUrl,
}) => {
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [avatarUri, setAvatarUri] = useState(
    "https://pbs.twimg.com/profile_images/1188747996843761665/8CiUdKZW_400x400.jpg"
  );

  useEffect(() => {
    setAvatarLoading(true);
    getAvatarUrl(uid)
      .then((downloadUri) => {
        setAvatarUri(downloadUri);
      })
      .finally(() => {
        setAvatarLoading(false);
      });
  }, [getAvatarUrl, setAvatarLoading, setAvatarUri]);

  if (avatarLoading) return <ActivityIndicator text={t("loading")} />;

  return <UserAvatar name={fallbackText} size={size} onTouchEnd={onTouchEnd} source={{ avatarUri }} />;
  // return (
  //   <Avatar size={size} bgColor={bgColor} onTouchEnd={onTouchEnd} source={{ uri: avatarUri }}>
  //     {fallbackText
  //       .split(" ")
  //       .slice(0, 2)
  //       .map((n) => n[0])
  //       .join("")
  //       .toUpperCase()}
  //   </Avatar>
  // );
};
