import { Collapse, List, Accordion } from "@ant-design/react-native";
import { useFocusEffect } from "@react-navigation/native";
import { t } from "i18n-js";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Platform, ScrollView, Dimensions } from "react-native";
import { useTestSessionsContext, useTestSettingsContext } from "../../providers/SettingsProvider";
import { FocusedBehaviourList } from "./FocusedBehaviourList";

import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../Firebase";
import { AllBehavioursList } from "./AllBehavioursList";

import { loadAsync } from "expo-font";

const withSettingsContext = (DimensionsCardsList) => {
  const Memoized = memo(DimensionsCardsList);

  return (props) => {
    const { settingsDispatch } = useTestSettingsContext();

    return <Memoized {...props} settingsDispatch={settingsDispatch} />;
  };
};

export const DimensionsCardsList = withSettingsContext(
  ({ activeSections, route, setActiveSections, settingsDispatch, isAllCollapsed, setIsAllCollapsed }) => {
    const {
      sessionsData: { sessions },
    } = useTestSessionsContext();

    const {
      settings: { tags },
    } = useTestSettingsContext();

    const [session, setSession] = useState(route?.params?.session);

    const showFocusedBehaviours = true;

    const showAllBehaviours = useMemo(() => {
      return session?.selectedBehaviours && session?.selectedBehaviours.length > 0 ? false : true;
    }, [session]);

    const setDrawerAdditionalItems = (payload) => {
      settingsDispatch({
        type: "setDrawerAdditionalItems",
        payload,
      });
    };

    const webStyles =
      Platform.OS === "web"
        ? {
            height: "70%",
          }
        : {
            paddingBottom: 125,
          };

    const toggleShowEvidences = () => {
      setIsAllCollapsed((prev) => {
        if (prev) setActiveSections([0]);
        else setActiveSections([1]);

        return !prev;
      });
    };

    useFocusEffect(
      useCallback(() => {
        if (!(showAllBehaviours && !showAllBehaviours)) {
          setDrawerAdditionalItems([
            {
              label: t("toggle.list"),
              callback: toggleShowEvidences,
              icon: "monitor",
            },
          ]);
        }
      }, [])
    );

    useEffect(() => {
      const unsubscribe = onSnapshot(doc(store, "sessions", session?.id ?? session?.key), (doc) => {
        const data = doc.data();
        if (data)
          setSession({
            ...session,
            ...data,
          });
      });

      setActiveSections([0]);
      loadAsync({
        antoutline: require("@ant-design/icons-react-native/fonts/antoutline.ttf"),
      });

      return unsubscribe;
    }, [route.params]);

    if (showFocusedBehaviours && session.selectedBehaviours?.length > 0) {
      if (Platform.OS !== "web")
        return (
          <ScrollView>
            <List style={{ marginBottom: 100 }}>
              <Collapse accordion defaultActiveKey={"0"} style={{ paddingBottom: 20 }}>
                <Collapse.Panel
                  key={"0"}
                  title={t("sessions.evaluation.featured")}
                  styles={{
                    Line: {
                      margin: 0,
                      padding: 0,
                    },
                    Item: {
                      padding: 0,
                      margin: 0,
                      width: "100%",
                    },
                  }}
                >
                  <FocusedBehaviourList session={session} selectedBehaviours={session?.selectedBehaviours} />
                </Collapse.Panel>
                <Collapse.Panel key={"1"} title={t("sessions.evaluation.all")}>
                  <AllBehavioursList isAllCollapsed={isAllCollapsed} session={session} />
                </Collapse.Panel>
              </Collapse>
            </List>
          </ScrollView>
        );

      return (
        <ScrollView>
          <Accordion
            activeSections={activeSections}
            onChange={setActiveSections}
            renderAsFlatList={false}
            style={{ backgroundColor: "white", ...webStyles }}
          >
            <Accordion.Panel header={t("sessions.evaluation.featured")} key={0}>
              <FocusedBehaviourList selectedBehaviours={session?.selectedBehaviours ?? []} session={session} />
            </Accordion.Panel>

            <Accordion.Panel header={t("sessions.evaluation.all")} key={1}>
              <AllBehavioursList isAllCollapsed={isAllCollapsed} session={session} />
            </Accordion.Panel>
          </Accordion>
        </ScrollView>
      );
    }

    return <AllBehavioursList isAllCollapsed={isAllCollapsed} session={session} />;
  }
);
