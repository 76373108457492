import React, { memo } from "react";

import { Modal, Button, View, WhiteSpace, Flex, WingBlank } from "@ant-design/react-native";
import CustomizedInputItem from "../../../components/Inputs/CustomizedInputItem";

import { t } from "../../../components";

export const WorkingGroupNameModal = memo(({ isOpen, onClose, onFinish, onChangeText, textValue }) => {
  return (
    <Modal
      visible={isOpen}
      onClose={onClose}
      title={t("sessions.add.subgroupNamePlaceholder") ?? "Example"}
      transparent={true}
    >
      <View testID="working-group-modal">
        <WhiteSpace size="sm" />
        <CustomizedInputItem onChangeText={onChangeText} defaultValue={textValue} />
        <WhiteSpace size="sm" />
        <Flex justify="center">
          <Button type="warning" onPress={onClose} style={{ width: "50%" }}>
            {t("cancel")}
          </Button>
          <WingBlank size="sm" />
          <Button
            style={{ width: "50%" }}
            type="primary"
            onPress={() => {
              onFinish();
              onClose();
            }}
          >
            {t("sessions.add.submit")}
          </Button>
        </Flex>
      </View>
    </Modal>
  );
});
