import { findIndex, isUndefined, keys } from "lodash";
import React, { memo, useContext, useState } from "react";
import { t } from "..";
import { AuthenticatedUserContext } from "../../providers";

import { getCategoryColor } from "..";

import { Flex, List, Text, View, WhiteSpace } from "@ant-design/react-native";

import {
  VotesContext,
  useDimensionsContext,
  useTestSettingsContext,
  useVotesContext,
} from "../../providers/SettingsProvider";

import { MemoizedModal } from "../../screens/SessionStack/SessionUserEvaluation/MemoizedModal";
import { MemoizedEvaluationStartRating } from "./DimensionsCards/MemoizedEvaluationStartRating";
import { MemoizedIcon } from "./DimensionsCards/MemoizedIcon";

const withSettingsAndDimensionsContext = (FocusedBehaviour) => {
  const Memoized = memo(FocusedBehaviour);
  return (props) => {
    const { settings } = useTestSettingsContext();
    const { dimensionsData } = useDimensionsContext();

    return <Memoized {...props} dimensionsData={dimensionsData} settings={settings} />;
  };
};

export const FocusedBehaviour = withSettingsAndDimensionsContext(({ behaviour, session, settings, dimensionsData }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);
  const { tags } = settings;

  const { withTranslation, dimensions: defaultDimensions } = dimensionsData;

  const { allVotes, setAllVotes } = useVotesContext(VotesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [comment, setComment] = useState({ evidence: null, comment: "" });

  const categories = keys(defaultDimensions);
  const evidence = behaviour.behaviour;
  const category = behaviour.category;

  const handleSave = (evidence, value) => {
    const newVotes = { ...allVotes };

    if (isUndefined(newVotes[category])) {
      newVotes[category] = {};
    }

    newVotes[category][evidence] = {
      ...(newVotes[category][evidence] || {}),
      tags: [...tags],
      timestamp: Math.round(+new Date() / 1000),
      ...value,
    };

    if (!isUndefined(session) && findIndex(newVotes[category][evidence].tags, (t) => t.includes("session")) === -1) {
      newVotes[category][evidence].tags.push(`session:${session.name}:${session.id}`);
    }

    if (value.value === 0) delete newVotes[category][evidence];
    if (value.comment === "") delete newVotes[category][evidence].comment;

    setAllVotes(newVotes);
  };

  return (
    <>
      <MemoizedModal
        modalVisible={modalVisible}
        handleSave={handleSave}
        comment={comment}
        setComment={setComment}
        setModalVisible={setModalVisible}
        category={category}
      />

      <List.Item key={`focused_behaviour_item_${Math.floor(Math.random() * 1000)}`} style={{ height: 130 }}>
        <Flex justify="between">
          <View
            style={{
              backgroundColor: getCategoryColor(category, categories),
              padding: 5,
              borderRadius: 5,
            }}
          >
            <Text style={{ color: "white" }}>
              {!withTranslation && t(`evaluation.${category}.title`)}
              {withTranslation && defaultDimensions[category][`title_${userProfile.profile.language || "en"}`]}
            </Text>
          </View>

          <MemoizedIcon
            setModalVisible={setModalVisible}
            setComment={setComment}
            comment={comment}
            item={behaviour.behaviour}
            modalVisible={modalVisible}
            category={behaviour.category}
          />
        </Flex>
        <WhiteSpace size="md" />
        <Text style={{ fontWeight: "bold" }}>
          {!withTranslation && t(`evaluation.${category}.dimensions.${evidence}`)}
          {withTranslation &&
            defaultDimensions[category].dimensions[evidence][`label_${userProfile.profile.language || "en"}`]}
        </Text>
        <MemoizedEvaluationStartRating category={behaviour.category} item={behaviour.behaviour} session={session} />

        <WhiteSpace size="md" />
      </List.Item>
    </>
  );
});
