import { get, ref, set } from 'firebase/database';
import { addDoc, collection, doc, getDoc, onSnapshot, query, setDoc, where } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { filter, find, findIndex, forEach, includes, isNil, isNull, isUndefined, keys, map } from 'lodash';
import { auth, db, functions, store } from '../components';

import { updateDoc } from 'firebase/firestore';

const sessionsRef = collection(store, 'sessions');

const getSessions = () => {
  const sessionsQuery = query(sessionsRef, where('users', 'array-contains', auth.currentUser.uid));
  const tempSessions = [];
  onSnapshot(sessionsQuery, (snapshot) => {
    allSessions = [];
    snapshot.forEach((session) => {
      const sessionData = session.data();
      if (!!sessionData.isDeleted) {
        tempSessions.push({ id: session.id, ...sessionData });
      }
    });
    setSessions(tempSessions);

    return true;
  });
};

const getCountryWhereSessionWasEnabled = async (sessionId) => {
  try {
    const sessionRef = doc(store, 'sessions', sessionId);
    const sessionSnap = await getDoc(sessionRef);

    let resultCountry = null;

    if (sessionSnap.exists()) {
      const sessionData = sessionSnap.data();

      const sessionStatsRef = doc(store, 'sessionStats', sessionId, 'entries', sessionData.statEntry);
      const sesisonStatsSnap = await getDoc(sessionStatsRef);

      if (sesisonStatsSnap.exists()) {
        const sessionStatsData = sesisonStatsSnap.data();
        resultCountry = sessionStatsData.location;
      }
    }

    return resultCountry[0].country;
  } catch (err) {
    console.log(err);
  }
};

const updateSession = async (id, data, sessions, options = { merge: true }) => {
  if (data.students.length !== data?.studentsKeys?.length || isNil(data.studentsKeys))
    data['studentsKeys'] = filter(
      map(data.students, ({ key }) => key),
      (v) => !isNil(v)
    );

  await setDoc(doc(sessionsRef, id), { ...data }, options);

  const sessionIndex = findIndex(sessions, (s) => s.id === id);
  sessions[sessionIndex] = { id, ...data };

  const { statEntry, students, enabled } = data;

  const missingStudents = [];

  if (!isNull(sessions) && sessions.length > 0 && enabled) {
    const sessionStatsRef = doc(store, 'sessionStats', id, 'entries', statEntry);
    const sessionStatsSnap = await getDoc(sessionStatsRef);

    const studentsToAdd = {};

    if (sessionStatsSnap.exists()) {
      const sessionStatsData = sessionStatsSnap.data();

      forEach(students, (student) => {
        const alreadyExistingStudents = map(sessionStatsData?.students, (v, key) => key);

        if (includes(alreadyExistingStudents, student.key)) {
          return (studentsToAdd[student.key] = sessionStatsData.students[student.key]);
        } else if (student && student.key) {
          studentsToAdd[student.key] = {
            given: 0,
            received: 0,
            sub: 0,
            self: 0,
          };
        }
      });

      if (keys(studentsToAdd).length > 0)
        updateDoc(sessionStatsRef, {
          ...sessionStatsData,
          students: {
            ...studentsToAdd,
          },
        });

      // check if student should not be delete from user profile
      // find missing students

      forEach(sessionStatsData.students, async (student, studentId) => {
        const missingStudent = find(students, ({ key }) => key.trim() === studentId.trim());

        if (!missingStudent) {
          missingStudents.push(studentId);
        }
      });
    }
  }

  forEach(missingStudents, (missingStudentKey) => {
    const studentsToPushToThisStudent = {};

    forEach(students, (student) => {
      if (isUndefined(student.key)) return;
      const studentKeyForStudentsTree =
        student.key === missingStudentKey ? `self_${student.key}` : `sub_${student.key}`;
      const { key, ...studentWithoutKey } = student;

      studentsToPushToThisStudent[studentKeyForStudentsTree] = {
        ...studentWithoutKey,
      };
    });

    set(ref(db, `users/${missingStudentKey}/students`), {});
    set(ref(db, `users/${missingStudentKey}/profile/tags`), []);

    if (!isNil(data.selectedBehaviours) && data.selectedBehaviours.length > 0) {
      set(ref(db, `users/${missingStudentKey}/profile/focusedDimensions`), {});
    }
  });

  forEach(students, ({ key: studentId, group }) => {
    const studentsToPushToThisStudent = {};
    const destinedStudentSubgroup = group.split('\\').length > 1 ? group.split('\\')[1].trim() : null;

    forEach(students, (student) => {
      const studentSubgroup = student.group.split('\\').length > 1 ? student.group.split('\\')[1].trim() : null;
      if (isUndefined(student.key)) return;
      if (!isNil(destinedStudentSubgroup) && isNil(studentSubgroup)) return;
      if (isNil(destinedStudentSubgroup) && !isNil(studentSubgroup)) return;
      if (!isNil(destinedStudentSubgroup) && !isNil(studentSubgroup) && destinedStudentSubgroup !== studentSubgroup)
        return;

      const studentKeyForStudentsTree = student.key === studentId ? `self_${student.key}` : `sub_${student.key}`;
      const { key, ...studentWithoutKey } = student;

      studentsToPushToThisStudent[studentKeyForStudentsTree] = {
        ...studentWithoutKey,
      };
    });

    set(ref(db, `users/${studentId}/students`), studentsToPushToThisStudent);
    set(ref(db, `users/${studentId}/profile/tags`), [`session:${data.name}:${data.key}:${data.statEntry}`]);

    if (!isNil(data.selectedBehaviours) && data.selectedBehaviours.length > 0) {
      set(ref(db, `users/${studentId}/profile/focusedDimensions`), { ...data.selectedBehaviours });
    }
  });

  return;
};

const createSession = (data, sessions) => {
  if (data.students.length !== data?.studentsKeys?.length)
    data['studentsKeys'] = filter(
      map(data.students, ({ key }) => key),
      (v) => !isNil(v)
    );

  return new Promise((resolve, reject) => {
    const enhancedData = { ...data, users: [auth.currentUser.uid] };

    addDoc(sessionsRef, enhancedData)
      .then((docRef) => {
        sessions.push({ id: docRef.id, key: docRef.id, ...enhancedData });
        resolve(sessions);
      })
      .catch((err) => reject(err));
  });
};

const deleteSession = (id, sessions) => {
  return new Promise((resolve, reject) => {
    const index = findIndex(sessions, (s) => s.id === id);
    const { idTemp, ...data } = sessions[index];
    data.isDeleted = true;

    setDoc(doc(sessionsRef, id), { ...data }, { merge: true })
      .then(() => {
        sessions.splice(index, 1);
        resolve(sessions);
      })
      .catch((err) => reject(err));
  });
};

const duplicateSession = (session, sessions, withAdministrators) => {
  return new Promise((resolve, reject) => {
    const { id, ...sessionData } = session;
    sessionData.name = sessionData.name + ' 1';
    const enhancedData = {
      ...sessionData,
      users: withAdministrators ? session.users : [auth.currentUser.uid],
      enabled: false,
    };
    addDoc(sessionsRef, enhancedData)
      .then((docRef) => {
        sessions.push({ id: docRef.id, ...enhancedData });
        resolve(sessions);
      })
      .catch((err) => reject(err));
  });
};

const verifySessionCode = async ({ token, accessCode }) => {
  try {
    const result = await httpsCallable(
      functions,
      'checkSessionAccessCode'
    )({
      token,
      accessCode,
    });
    return result;
  } catch (err) {
    return { data: { authorized: false } };
  }
};

const logInStudentToSession = async ({ token, sessionId, selectedStudentId }) => {
  try {
    const result = await httpsCallable(
      functions,
      'grandAccessToSession'
    )({
      token,
      sessionId,
      selectedStudentId,
    });

    return { authorized: true, authToken: result.data.authToken };
  } catch (err) {
    throw new Error('UNAUTHORIZED OR ALREADY CLAIMED');
  }
};

const NOTIFICATION_SEND_ERROR = 'error while sending notification';

const findDeviceTokensForUsers = async (usersId) => {
  try {
    const tokens = [];

    for (const uid of usersId) {
      const { deviceToken } = (await get(ref(db, `users/${uid}/profile/`))).val();
      tokens.push(deviceToken);
    }

    if (includes(tokens, undefined)) throw new Error();
    return tokens;
  } catch (err) {
    return false;
  }
};

const sendNotificationToUser = async ({ token, title, body, usersId }) => {
  try {
    const devicesTokens = await findDeviceTokensForUsers(usersId);

    if (!devicesTokens) throw new Error();

    const result = await httpsCallable(
      functions,
      'sendNotifications'
    )({
      token,
      devicesTokens,
      title,
      body,
    });

    if (!result) throw new Error(NOTIFICATION_SEND_ERROR);

    return true;
  } catch (err) {
    throw new Error(err);
  }
};

const findUser = async ({ tenant, userEmail }) => {
  try {
    const tenantUsersSnap = await get(ref(db, `tenants/${tenant}/users/`));
    const snapshot = await tenantUsersSnap.val();

    const user = find(snapshot, ({ email }) => {
      if (isNil(email)) return false;
      return email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });

    if (!isNil(user)) return true;

    return false;
  } catch (err) {
    return false;
  }
};

export {
  createSession,
  deleteSession,
  duplicateSession,
  findUser,
  getCountryWhereSessionWasEnabled,
  getSessions,
  logInStudentToSession,
  sendNotificationToUser,
  updateSession,
  verifySessionCode,
};
