import React, { memo, useState } from "react";
import { AirbnbRating } from "react-native-ratings";
import { getValueBasedColor } from "./helpers/getValueBasedColor";
import { View } from "@ant-design/react-native";

export const EvaluationStarRating = memo(({ onStarRatingChange, defaultRating = 0 }) => {
  const [value, setValue] = useState(defaultRating);

  const ratingChange = (rating) => {
    if (rating === value) rating = 0;
    setValue(rating);
    onStarRatingChange(rating);
  };

  return (
    <View testID="star-rating">
      <AirbnbRating
        count={5}
        value={value}
        onFinishRating={ratingChange}
        selectedColor={getValueBasedColor(value)}
        defaultRating={defaultRating}
        showRating={false}
        starContainerStyle={{ justifyContent: "space-between", width: "95%" }}
      />
    </View>
  );
});
