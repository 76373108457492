import React, { memo, useContext, useEffect, useState } from "react";
import { Platform, Pressable, Text } from "react-native";

import { keys } from "lodash";
import { getCategoryColor, t } from "../..";
import { AuthenticatedUserContext } from "../../../providers";

import { Accordion, Collapse, Flex, Icon, Modal, View, WhiteSpace } from "@ant-design/react-native";

import { useDimensionsContext } from "../../../providers/SettingsProvider";
import { DimensionsCardChild } from "./DimensionsCardChild";
import { ModalWithDimensionsContext } from "./ModalWithDimensionsContext";

export const withDimensionsContext = (Component) => {
  const Memoized = memo(Component);

  return (props) => {
    const { dimensionsData } = useDimensionsContext();

    return <Memoized {...props} dimensionsData={dimensionsData} />;
  };
};

export const DimensionCard = withDimensionsContext(({ category, session, isAllCollapsed, dimensionsData }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);

  const { withTranslation, dimensions: defaultDimensions, reducedDimensions } = dimensionsData;

  const dimensions = reducedDimensions
    ? reducedDimensions[category]?.dimensions
    : defaultDimensions[category]?.dimensions;

  const categories = keys(defaultDimensions);

  const [modalVisible, setModalVisible] = useState(false);
  const [comment, setComment] = useState({ evidence: null, comment: "" });
  const [activeSections, setActiveSection] = useState([]);

  const toggleShowDescriptions = (text) => {
    Modal.operation([{ text: text }]);
  };

  useEffect(() => {
    setActiveSection(isAllCollapsed ? [] : [0]);
  }, [isAllCollapsed]);

  return (
    <View>
      <ModalWithDimensionsContext
        modalVisible={modalVisible}
        comment={comment}
        setComment={setComment}
        setModalVisible={setModalVisible}
        category={category}
        session={session}
      />

      {keys(dimensions).filter((x) => x !== "otherComments").length !== 0 && Platform.OS === "web" ? (
        <Accordion onChange={setActiveSection} activeSections={activeSections}>
          <Accordion.Panel
            key={0}
            header={
              <Pressable style={{ width: "100%", padding: 5 }}>
                <Flex justify="between">
                  <View
                    style={{
                      backgroundColor: getCategoryColor(category, categories),
                      padding: 5,
                      borderRadius: 5,
                    }}
                    testID="category-bar"
                  >
                    <Text style={{ color: "white" }} testID="category-tag">
                      {!withTranslation && t(`evaluation.${category}.title`)}
                      {withTranslation && defaultDimensions[category][`title_${userProfile.file.language || "en"}`]}
                    </Text>
                  </View>

                  <Pressable onPress={() => toggleShowDescriptions(t(`evaluation.${category}.description`))}>
                    <Icon testID="info-circle" name="info-circle" />
                  </Pressable>
                </Flex>
              </Pressable>
            }
          >
            <DimensionsCardChild
              comment={comment}
              setComment={setComment}
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              category={category}
              session={session}
            />
          </Accordion.Panel>
        </Accordion>
      ) : (
        <Collapse accordion>
          <Collapse.Panel
            key="1"
            title={
              <Pressable style={{ width: "100%", padding: 5 }}>
                <Flex justify="between">
                  <View
                    style={{
                      backgroundColor: getCategoryColor(category, categories),
                      padding: 5,
                      borderRadius: 5,
                    }}
                    testID="category-bar"
                  >
                    <Text style={{ color: "white" }} testID="category-tag">
                      {!withTranslation && t(`evaluation.${category}.title`)}
                      {withTranslation && defaultDimensions[category][`title_${userProfile.file.language || "en"}`]}
                    </Text>
                  </View>

                  <Pressable onPress={() => toggleShowDescriptions(t(`evaluation.${category}.description`))}>
                    <Icon testID="info-circle" name="info-circle" />
                  </Pressable>
                </Flex>
              </Pressable>
            }
          >
            <DimensionsCardChild
              comment={comment}
              setComment={setComment}
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              category={category}
              session={session}
            />
          </Collapse.Panel>
        </Collapse>
      )}

      <WhiteSpace size="md" />
    </View>
  );
});
