import { Flex, List, WhiteSpace, View } from "@ant-design/react-native";
import { includes, keys } from "lodash";
import React, { memo, useCallback, useContext } from "react";
import { FlatList, Text } from "react-native";
import { t } from "../..";
import { AuthenticatedUserContext } from "../../../providers";
import { useDimensionsContext, useTestSettingsContext, useVotesContext } from "../../../providers/SettingsProvider";

import { MemoizedIcon } from "./MemoizedIcon";
import { MemoizedEvaluationStartRating } from "./MemoizedEvaluationStartRating";

export const withDimensionsAndSettingsContext = (Component) => {
  const Memoized = memo(Component);

  return (props) => {
    const { dimensionsData } = useDimensionsContext();
    const {
      settings: { tags },
    } = useTestSettingsContext();

    return <Memoized {...props} dimensionsData={dimensionsData} tags={tags} />;
  };
};

export const DimensionsCardChild = withDimensionsAndSettingsContext(
  ({ comment, setComment, setModalVisible, modalVisible, category, session, dimensionsData }) => {
    const { dimensions: defaultDimensions, withTranslation, reducedDimensions } = dimensionsData;

    const { userProfile } = useContext(AuthenticatedUserContext);
    const { allVotes } = useVotesContext();

    const dimensions = reducedDimensions
      ? reducedDimensions[category]?.dimensions
      : defaultDimensions[category]?.dimensions;

    const renderItem = useCallback(
      ({ item }) => {
        return (
          <List.Item>
            <View testID="behaviour-item">
              <Flex justify={"between"}>
                <Text style={{ fontWeight: "bold", maxWidth: "80%" }} testID="behaviour-desc">
                  {!withTranslation && includes(t(`evaluation.${category}.dimensions.${item}`), "/")
                    ? t(`evaluation.${category}.dimensions.${item}`).split("/").join(" | ")
                    : t(`evaluation.${category}.dimensions.${item}`)}

                  {withTranslation &&
                    defaultDimensions?.[category]?.dimensions?.[item]?.[
                      `label_${userProfile.profile.language || "en"}`
                    ]}
                </Text>
                <MemoizedIcon
                  setModalVisible={setModalVisible}
                  setComment={setComment}
                  comment={comment}
                  item={item}
                  modalVisible={modalVisible}
                  category={category}
                />
              </Flex>
              {item !== "otherComments" && (
                <MemoizedEvaluationStartRating category={category} item={item} session={session} />
              )}
              <WhiteSpace size="md" />
            </View>
          </List.Item>
        );
      },
      [comment, allVotes, session]
    );

    return (
      <FlatList
        data={keys(dimensions).filter((x) => x !== "otherComments")}
        renderItem={renderItem}
        initialNumToRender={keys(dimensions).filter((x) => x !== "otherComments").length}
        maxToRenderPerBatch={3}
        keyExtractor={(item) => `${item}_${Math.floor(Math.random() * 1000)}`}
      />
    );
  }
);
