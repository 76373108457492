import React, { useCallback, useEffect, useState } from "react";

import { keys } from "lodash";

import { ActivityIndicator, Flex, View } from "@ant-design/react-native";

import { Loading, SearchBar, t } from "../../components";

import { useFocusEffect } from "@react-navigation/native";
import { DimensionsCardsList } from "../../components/behavioursShared/DimensionsCardsList";
import {
  VotesContext,
  useDimensionsContext,
  useTestSettingsContext,
  useVotesContext,
} from "../../providers/SettingsProvider";
import { SaveButtonsStack } from "../SessionStack/SessionUserEvaluation/SaveButton";

export const UserEvaluation = ({ route, navigation }) => {
  const [loading, setLoading] = useState(true);

  const [requestPending, setIsRequestPending] = useState(false);

  const [activeSections, setActiveSections] = useState([0]);

  const [isAllCollapsed, setIsAllCollapsed] = useState(true);

  const {
    settings: { tags },
  } = useTestSettingsContext();

  const {
    dimensionsData: { dimensions, loadingDimensions, reducedDimensions, focusedDimensions },
    dimensionsDispatch,
  } = useDimensionsContext();

  const { setAllVotes } = useVotesContext(VotesContext);

  const setReducedDimensions = (payload) => {
    dimensionsDispatch({
      type: "setReducedDimensions",
      payload,
    });
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        setAllVotes({});
      };
    }, [])
  );

  const searchDimensions = (text) => {
    let newDimensions = {};

    keys(dimensions).forEach((category) => {
      const filteredEvidences = keys({ ...dimensions[category].dimensions })
        .filter((evidence) => !evidence.includes("otherComments"))
        .filter((behaviour) =>
          t(`evaluation.${category}.dimensions.${behaviour}`).toString().toLowerCase().includes(text.toLowerCase())
        );

      if (filteredEvidences.length > 0) {
        newDimensions[category] = {};
        newDimensions[category].title = dimensions[category].title;
        newDimensions[category].description = dimensions[category].description;
        newDimensions[category].dimensions = {};
        filteredEvidences.forEach((ev) => {
          newDimensions[category].dimensions[ev] = dimensions[category].dimensions[ev];
        });
      }
    });
    setReducedDimensions(newDimensions);
  };

  const resetDimensions = () => {
    setReducedDimensions(null);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loadingDimensions) return <Loading />;

  return (
    <View style={{ position: "relative", height: "98%" }}>
      <Flex justify="center">
        <SearchBar onChangeText={searchDimensions} showClose={reducedDimensions} resetFunction={resetDimensions} />
      </Flex>

      <ActivityIndicator animating={requestPending} toast={true} size="large" text={t("loading")} />

      {!loading ? (
        <DimensionsCardsList
          route={route}
          isAllCollapsed={isAllCollapsed}
          setIsAllCollapsed={setIsAllCollapsed}
          navigation={navigation}
          selectedBehaviours={focusedDimensions}
          activeSections={activeSections}
          setActiveSections={setActiveSections}
        />
      ) : (
        <Loading />
      )}

      <SaveButtonsStack isLoading={false} route={route} setIsLoading={setIsRequestPending} />
    </View>
  );
};
