import React, { useCallback, useContext, useEffect, useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";

import { ActivityIndicator, Button, Flex, Icon, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import logoIcon from "../assets/logoIcon.png";
import { auth, localizeInit, t } from "../components";

// import VersionCheck from "react-native-version-check-expo";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Image, Platform, Text } from "react-native";
import CustomizedInputItem from "../components/Inputs/CustomizedInputItem";

import { AuthenticatedUserContext } from "../providers";

import { useFocusEffect } from "@react-navigation/native";
import { getLocales } from "expo-localization";
import { includes } from "lodash";

const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

localizeInit();

export const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(true);

  const { setLanguage, setUser, user } = useContext(AuthenticatedUserContext);

  const onLogin = async () => {
    try {
      setLoading(true);
      if (email != "" && password != "") {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (err) {
      Toast.fail({
        content: t("login.error"),
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  const goToResetPassword = () => {
    navigation.navigate("ResetPassword");
  };

  const onStudentLogin = () => {
    navigation.navigate("StudentLogin");
  };

  useFocusEffect(
    useCallback(() => {
      if (includes(["en", "es", "ca"], getLocales()[0].languageCode)) {
        setLanguage(getLocales()[0].languageCode);
        localizeInit(getLocales()[0].languageCode);
      }
    }, [auth])
  );

  useEffect(() => {
    const removeChangelogFlag = async () => {
      try {
        await AsyncStorage.removeItem("changelogWasViewed");

        return true;
      } catch (err) {
        console.log(err);
      }
    };

    const checkVersion = async () => {
      const versioning = await VersionCheck.needUpdate();

      if (versioning?.isNeeded) {
        navigation.navigate("UpdateVersion", {
          storeUrl: versioning?.storeUrl,
        });
        removeChangelogFlag();
      }
    };

    if (Platform.OS !== "web") checkVersion();
  }, []);

  if (Platform.OS === "web" && integrationLoading)
    return (
      <View style={{ width: "100%", height: "100%" }} testID="loading-section">
        <Flex direction="column" justify="center" align="center" style={{ width: "100%", height: "100%" }}>
          <Image source={logoIcon} style={{ height: 82, width: 82 }} alt="Pentabilities" data-cy="mobile-penta-logo" />
          <WhiteSpace size="md" />
          <ActivityIndicator testID="mobile-login-loading" />
        </Flex>
      </View>
    );

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <Flex direction="column" justify="center" align="center" style={{ width: "100%", height: "100%" }}>
        <Image source={logoIcon} style={{ height: 82, width: 82 }} alt="Pentabilities" />

        <WhiteSpace size="lg" />

        <View style={{ width: "100%" }}>
          <WingBlank size="sm">
            <CustomizedInputItem placeholder={t("login.email")} onChangeText={(email) => setEmail(email.trim())} />

            <WhiteSpace size="lg" />

            <CustomizedInputItem
              placeholder={t("login.password")}
              type={passwordVisibility ? "text" : "password"}
              secureTextEntry={true}
              onChangeText={(password) => setPassword(password)}
              extra={
                <Text
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fafafa",
                    textAlignVertical: "center",
                    paddingRight: 8,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  <Icon
                    onPress={() => setPasswordVisibility((prev) => !prev)}
                    name={passwordVisibility ? "eye" : "eye-invisible"}
                  />
                </Text>
              }
            />

            <WhiteSpace size="lg" />
          </WingBlank>
          <WingBlank size="lg">
            <Button onPress={onLogin} type="primary" loading={loading}>
              <Text style={{ color: "#fff" }}>{t("login.submit")}</Text>
            </Button>
          </WingBlank>
          <WhiteSpace size="md" />
          {/* <WingBlank size="lg">
            <Button type="ghost" onPress={onStudentLogin}>
              <Text>{t("login.student")}</Text>
            </Button>
          </WingBlank> */}
        </View>

        <WhiteSpace size="md" />

        <Flex align="center">
          <Text onPress={goToResetPassword} style={{ textDecorationLine: "underline" }}>
            {t("login.resetPassword")}
          </Text>
        </Flex>
      </Flex>
    </View>
  );
};
