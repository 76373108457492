import { Toast } from "@ant-design/react-native";
import { findIndex, indexOf, isNil } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { FlatList, RefreshControl } from "react-native";
import { auth, getColor, Loading, t } from "../../../components";
import { StudentItemSelectable } from "../../../components/studentShared/StudentItemSelectable";
import { useStudentSessionsContext, useTestSettingsContext } from "../../../providers/SettingsProvider";
import { EmptyList } from "./EmptyList";

export const StudentsList = memo(({ reducedStudentsListForStudentsScreen, allStudents, groupList, navigate }) => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    settings: { tags },
  } = useTestSettingsContext();

  const {
    studentSessionsData: { currentSession },
  } = useStudentSessionsContext();

  const navigateToUserEvaluation = (item) => {
    if (!isNil(currentSession) && currentSession.enabled)
      navigate("UserEvaluation", { student: item, session: currentSession });
    else
      Toast.fail({
        duration: 3,
        content: t("sessions.haveToBeEnabled"),
      });
  };

  const renderItem = useCallback(
    ({ item, index }) => {
      if (item)
        return (
          <StudentItemSelectable
            item={item}
            avatarColor={getColor(item?.group, groupList)}
            onPress={() => navigateToUserEvaluation(item)}
          />
        );
    },
    [currentSession]
  );

  useEffect(() => {
    if (listData !== reducedStudentsListForStudentsScreen && listData !== allStudents) {
      const adjustedNewListData = reducedStudentsListForStudentsScreen
        ? reducedStudentsListForStudentsScreen
        : allStudents;

      const indexOfCurrentUser = findIndex(adjustedNewListData, (v) => (v?.id ?? v?.key) === auth.currentUser.uid);

      if (indexOfCurrentUser) {
        const copyOfUser = adjustedNewListData[indexOfCurrentUser];
        adjustedNewListData.splice(indexOfCurrentUser, 1);
        adjustedNewListData.unshift(copyOfUser);
      }

      setListData(adjustedNewListData);
      setLoading(false);
    }
  }, [loading, reducedStudentsListForStudentsScreen, listData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <FlatList
      data={currentSession && currentSession?.enabled ? listData : []}
      ListEmptyComponent={<EmptyList />}
      contentContainerStyle={{ paddingBottom: 280 }}
      refreshing={loading}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={() => listData} />}
      renderItem={renderItem}
      numColumns={1}
      initialNumToRender={9}
    />
  );
});
