import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useContext, useEffect } from "react";

import { Platform } from "react-native";
import { LoginScreen } from "../screens/LoginScreen";
import { ResetPasswordScreen } from "../screens/ResetPasswordScreen";

import StudentLoginScreen from "../screens/StudentsLogin/StudentLoginScreen";

import SessionStudentsAuthenticationList from "../screens/StudentsLogin/SessionStudentsAuthenticationList";

import UpdateVersionScreen from "../screens/UpdateVersionScreen";

import { AuthenticatedUserContext } from "../providers";

import { signOut } from "firebase/auth";
import { isNil } from "lodash";
import { handleIframeRedirection } from "../helpers/handleIframeRedirection";
import { decrypt, handleLoginFromPlatform } from "./encryptionsHelpers";
import { auth } from "./Firebase";

const Stack = createNativeStackNavigator();
const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

export default function AuthStack() {
  const { user, setUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (Platform.OS === "web") {
      const isUidMatched = user?.uid === window.localStorage.getItem("userId");

      if (user && !isUidMatched) {
        signOut(auth).then(() => {
          setUser(null);
        });
      }

      handleIframeRedirection(window, document);

      const listener = window.addEventListener("message", ({ data }) => {
        if (!isNil(data.chg) && user)
          signOut(auth).then(() => {
            setUser(null);
          });

        const encryptedLogin = data?.l1 ?? null;
        const encryptedPassword = data?.p1 ?? null;

        if (encryptedLogin && encryptedPassword) {
          const decryptedLogin = decrypt(encryptedLogin, SECRET_KEY.trim());
          const decyptedPassword = decrypt(encryptedPassword, SECRET_KEY.trim());

          window.localStorage.setItem("login", decryptedLogin);
          window.localStorage.setItem("password", decyptedPassword);

          if (decryptedLogin.toLowerCase() !== window.localStorage.getItem("userEmail").toLowerCase())
            signOut(auth).then(() => {
              setUser(null);
            });

          if (!user)
            handleLoginFromPlatform({
              login: decrypt(encryptedLogin, SECRET_KEY.trim()),
              password: decrypt(encryptedPassword, SECRET_KEY.trim()),
            });
        }
        return;
      });

      setTimeout(() => {
        handleLoginFromPlatform({
          login: window.localStorage.getItem("login"),
          password: window.localStorage.getItem("password"),
        });
      }, 3000);

      return () => listener;
    }
  }, []);

  return (
    <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="UpdateVersion" component={UpdateVersionScreen} />
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
      <Stack.Screen name="StudentLogin" component={StudentLoginScreen} />
      <Stack.Screen name="SessionStudentsList" component={SessionStudentsAuthenticationList} />
    </Stack.Navigator>
  );
}
