import React, { useContext, useEffect } from "react";

import { Platform, Text } from "react-native";

import { Flex, View } from "@ant-design/react-native";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { DrawerToggleButton } from "@react-navigation/drawer";
import StudentSessionsScreen from "../screens/EvaluationStack/StudentSessionsScreen";
import { StudentsScreen } from "../screens/EvaluationStack/StudentsScreen";
import { UserEvaluation } from "../screens/EvaluationStack/UserEvaluation";

import { handleIframeRedirection } from "../helpers/handleIframeRedirection";
import { AuthenticatedUserContext } from "../providers";
import GoBackIcon from "./GoBackIcon";

import { signOut } from "firebase/auth";
import { isNil } from "lodash";
import { decrypt, handleLoginFromPlatform } from "./encryptionsHelpers";
import { auth } from "./Firebase";

const Stack = createNativeStackNavigator();
const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

export default function EvaluationStack() {
  const { user, setUser } = useContext(AuthenticatedUserContext);

  const defaultOptions =
    Platform.OS === "web"
      ? {
          headerShown: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: "transparent",
          },
          headerLeft: () => <></>,
          headerShadowVisible: false,
        }
      : {
          headerShown: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: "transparent",
          },
          headerRight: () => <DrawerToggleButton />,
          headerShadowVisible: false,
        };

  useEffect(() => {
    if (Platform.OS === "web") {
      const isUidMatched = user?.uid === window.localStorage.getItem("userId");

      if (user && !isUidMatched) {
        signOut(auth).then(() => {
          setUser(null);
        });
      }

      handleIframeRedirection(window, document);

      const listener = window.addEventListener("message", ({ data }) => {
        if (!isNil(data.chg) && user)
          signOut(auth).then(() => {
            setUser(null);
          });

        const encryptedLogin = data?.l1 ?? null;
        const encryptedPassword = data?.p1 ?? null;

        if (encryptedLogin && encryptedPassword) {
          const decryptedLogin = decrypt(encryptedLogin, SECRET_KEY.trim());
          const decyptedPassword = decrypt(encryptedPassword, SECRET_KEY.trim());

          window.localStorage.setItem("login", decryptedLogin);
          window.localStorage.setItem("password", decyptedPassword);

          if (decryptedLogin.toLowerCase() !== window.localStorage.getItem("userEmail").toLowerCase())
            signOut(auth).then(() => {
              setUser(null);
            });

          if (!user)
            handleLoginFromPlatform({
              login: decrypt(encryptedLogin, SECRET_KEY.trim()),
              password: decrypt(encryptedPassword, SECRET_KEY.trim()),
            });
        }
        return;
      });

      setTimeout(() => {
        handleLoginFromPlatform({
          login: window.localStorage.getItem("login"),
          password: window.localStorage.getItem("password"),
        });
      }, 3000);

      return () => listener;
    }
  }, []);

  return (
    <Stack.Navigator initialRouteName="StudentSessions" screenOptions={defaultOptions}>
      <Stack.Screen name="StudentSessions" component={StudentSessionsScreen} options={{ title: "Student Sessions" }} />
      <Stack.Screen
        name="Students"
        component={StudentsScreen}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const webOptions =
            Platform.OS === "web"
              ? {
                  headerLeft: () => <GoBackIcon callback={navigation.goBack} />,
                }
              : {};

          return {
            ...(routes?.[index]?.params ?? {}),
            ...webOptions,
          };
        }}
      />
      <Stack.Screen
        name="UserEvaluation"
        component={UserEvaluation}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const { student } = routes[index]?.params;

          return {
            headerBackVisible: false,
            headerTitle: () => (
              <View>
                <Flex>
                  <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                    {student.name}
                  </Text>
                </Flex>
                <Flex>
                  <Text numberOfLines={1}>{student.group.trim()}</Text>
                </Flex>
              </View>
            ),
          };
        }}
      />
    </Stack.Navigator>
  );
}
