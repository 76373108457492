import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FlatList } from "react-native";
import { FocusedBehaviour } from "./FocusedBehaviour";
import { Loading } from "../Loading";
import { isNil } from "lodash";

export const FocusedBehaviourList = ({ selectedBehaviours, session }) => {
  const [listData, setListData] = useState(selectedBehaviours);
  const [isLoading, setIsLoading] = useState(true);

  const renderItem = useCallback(
    ({ item, index }) => {
      return <FocusedBehaviour behaviour={item} session={session} key={index} />;
    },
    [selectedBehaviours, session]
  );

  const getItemLayout = (data, index) => ({
    length: 130,
    offset: 130 * index,
    index,
  });

  const initialNumToRender = useMemo(() => {
    return selectedBehaviours?.length && !isNil(selectedBehaviours) ? selectedBehaviours.length : 3;
  }, [selectedBehaviours]);

  useEffect(() => {
    if (listData === selectedBehaviours) {
      setIsLoading(false);
    } else {
      setListData(selectedBehaviours);
      setIsLoading(false);
    }
  }, [selectedBehaviours, session]);

  if (isLoading) return <Loading />;

  return (
    <FlatList
      data={listData}
      renderItem={renderItem}
      getItemLayout={getItemLayout}
      keyExtractor={() => `focusedBehaviour_item_` + Math.floor(Math.random() * 100000)}
      initialNumToRender={initialNumToRender}
      maxToRenderPerBatch={3}
    />
  );
};
