import React, { memo, useCallback, useContext, useState } from "react";

import { Platform, Pressable, StyleSheet, Text } from "react-native";

import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18n-js";

import { auth, functions, store } from "../../../components";
import { useLocation } from "../../../hooks/useLocation";
import { AuthenticatedUserContext } from "../../../providers";
import { updateSession } from "../../../services";
import { SessionCategorySelectModal } from "../SessionCategorySelectModal";
import { SessionCardMenu } from "./SessionCardMenu";
import { SessionModal } from "./SessionModal";

import { findUser } from "../../../services/SessionsService";

import { Card, Flex, Icon, Switch, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { FeedbackModal } from "../../../components/Feedback/Modal/FeedbackModal";
import GoBackIcon from "../../../components/GoBackIcon";
import { useTestSettingsContext } from "../../../providers/SettingsProvider";

const MemoizedSwitch = memo(({ enabled, toggleSession, enableInProgress }) => {
  const { address } = useLocation();

  return (
    <View testID="toggle-session-switch">
      <Flex justify="end">
        <Switch
          defaultChecked={enabled}
          checked={enabled}
          color="orange"
          size="lg"
          onChange={() => toggleSession({ location: { country: address?.[0].country ?? "" } })}
          loading={enableInProgress}
        />
      </Flex>
    </View>
  );
});

const styles = StyleSheet.create({
  toastText: {
    fontWeight: "bold",
    color: "white",
  },
});

export const SessionCard = memo(({ session, sessions, setSessions, handleSessionUpdate, navigation, index = 0 }) => {
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [enableInProgress, setEnableInProgress] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [feedbackStatus, setFeedBackStatus] = useState("pending");

  const [errorMessage, setErrorMessage] = useState(t("updatesHandling.generalError"));
  const [successMessage, setSuccessMessage] = useState(t("updatesHandling.updated"));

  const [selectedBehaviours, setSelectedBehaviours] = useState(
    session.selectedBehaviours ? session.selectedBehaviours : []
  );

  const [behaviourSelectionModalOpen, setBehaviourSelectionModalOpen] = useState(false);
  const [sessionStats, setSessionStats] = useState(null);

  const {
    settings: { tags },
  } = useTestSettingsContext();

  const { userProfile } = useContext(AuthenticatedUserContext);

  const enableSessionStats = useCallback((statEntry) => {
    if (!session && !statEntry) return;

    onSnapshot(doc(store, `sessionStats/${session.id}/entries/${statEntry}`), (sE) => {
      if (!sE.data()) return;
      setSessionStats(sE.data());
    });
  }, []);

  const handleSelectedBehavioursSave = useCallback((value) => {
    const { id, ...newSession } = session;
    newSession["selectedBehaviours"] = value;

    setSuccessMessage(t("updatesHandling.updated"));

    updateSession(id, newSession, sessions)
      .then(() => {
        Toast.info({
          content: t("saveSuccess"),
          duration: 1,
        });
      })
      .catch((err) =>
        Toast.info({
          content: (
            <>
              <Text style={styles.toastText}>{t("saveError")}</Text>
              <Text style={styles.toastText}>{err.message}</Text>
            </>
          ),
          duration: 1,
        })
      );
  }, []);

  const toggleSession = useCallback(({ location }) => {
    setEnableInProgress(true);
    const toggleSession = httpsCallable(functions, "toggleSession");

    toggleSession({
      sessionId: session?.id ?? session?.key,
      requestedUserId: auth.currentUser.uid,
      tags: tags,
      location,
    })
      .then((response) => {
        if (response.data.status === "enabled") {
          Toast.info({
            content: t("sessions.enabled"),
            duration: 3,
          });

          enableSessionStats(response.data.statEntry);
        } else {
          Toast.info({
            content: (
              <>
                <Text style={styles.toastText}>{t("sessions.disabled")}</Text>
                {response.data.stats && (
                  <>
                    <Text style={styles.toastText}>{t("sessions.stats.title")}</Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.overall")}: <Text>{response.data.stats.all}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.group")}: <Text>{response.data.stats.group}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.self")}: <Text>{response.data.stats.self}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.evaluators")}: <Text>{response.data.stats.evaluators}</Text>
                    </Text>
                  </>
                )}
              </>
            ),
            duration: 3,
          });
        }
        setEnableInProgress(false);
        setSessionStats(null);
      })
      .catch((err) => {
        Toast.info({
          content: t("error"),
          duration: 1,
        });

        setEnableInProgress(false);
      });
  }, []);

  const shareSession = async (userEmail) => {
    try {
      const shareSessionFunction = httpsCallable(functions, "shareSession");
      const tenant = userProfile?.profile?.tenant ?? "";

      setIsFeedbackModalVisible(true);

      if (tenant === "") {
        return setFeedBackStatus("error");
      }

      const result = await findUser({ tenant, userEmail });

      if (!result) {
        throw new Error("No matching users");
      }
      await shareSessionFunction({
        userEmailToAdd: userEmail,
        requestedUserId: auth.currentUser.uid,
        sessionId: session.id,
      }).catch((err) => {
        setFeedBackStatus("error");
        setErrorMessage(t("updatesHandling.generalError"));
      });

      setFeedBackStatus("success");
    } catch (err) {
      setFeedBackStatus("error");
      setErrorMessage(t("sessions.sharingError"));
    }
  };

  const moveToSessionStudentList = () => {
    const options =
      Platform.OS === "web"
        ? {
            session: session,
            title: session.name,
            headerLeft: () => <GoBackIcon callback={navigation.goBack} styles={{ style: { marginLeft: 5 } }} />,
          }
        : {
            session: session,
            title: session.name,
          };

    navigation.navigate("SessionStudents", {
      ...options,
    });
  };

  const handleRenameSession = async (newValue) => {
    try {
      setIsFeedbackModalVisible(true);
      setSuccessMessage(t("updatesHandling.updated"));
      await handleSessionUpdate(session, "name", newValue);
      setFeedBackStatus("success");
    } catch (err) {
      setErrorMessage(t("updatesHandling.generalError"));
      setFeedBackStatus("error");
    }
  };

  return (
    <View testID="session-card">
      <SessionModal
        value={session.name}
        title={t("sessions.list.renameTitle")}
        visible={renameModalVisible}
        onClose={() => {
          setRenameModalVisible(false);
          setFeedBackStatus("pending");
        }}
        onSave={handleRenameSession}
      />
      <SessionModal
        value={""}
        title={t("sessions.list.shareTitle")}
        visible={shareModalVisible}
        onClose={() => {
          setShareModalVisible(false);
          setFeedBackStatus("pending");
        }}
        onSave={(newValue) => shareSession(newValue)}
      />
      <FeedbackModal
        visible={isFeedbackModalVisible}
        setModalVisible={setIsFeedbackModalVisible}
        pendingMessage={t("updatesHandling.saving")}
        successMessage={successMessage}
        errorMessage={errorMessage}
        status={feedbackStatus}
      />
      <SessionCategorySelectModal
        session={session}
        modalOpen={behaviourSelectionModalOpen}
        onClose={() => setBehaviourSelectionModalOpen(false)}
        onSave={handleSelectedBehavioursSave}
        selectedBehaviours={selectedBehaviours}
        setSessions={setSessions}
        sessions={sessions}
        setSelectedBehaviours={setSelectedBehaviours}
      />

      <WingBlank size="lg">
        <Card>
          <Card.Header
            title={<Text testID="session-name">{session.name}</Text>}
            thumb={
              <View testID="session-card-popover-trigger">
                <SessionCardMenu
                  enableInProgress={enableInProgress}
                  placement={index > 1 ? "auto" : "bottom"}
                  session={session}
                  setRenameModalVisible={setRenameModalVisible}
                  setShareModalVisible={setShareModalVisible}
                  setBehaviourSelectionModalOpen={setBehaviourSelectionModalOpen}
                  toggleSession={toggleSession}
                />
              </View>
            }
            extra={
              <MemoizedSwitch
                toggleSession={toggleSession}
                enabled={session.enabled}
                enableInProgress={enableInProgress}
              />
            }
          />

          <Card.Footer
            content={
              <Pressable onPress={moveToSessionStudentList}>
                <Flex justify="between">
                  <Text>{t("sessions.add.students")}</Text>
                  <Icon name="double-right" size="sm" testID="session-card-go-to-students" />
                </Flex>
              </Pressable>
            }
          />
        </Card>
      </WingBlank>
      <WhiteSpace size="sm" />
    </View>
  );
});
