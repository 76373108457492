import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { filter, find, groupBy, includes, keys, orderBy } from "lodash";
import { Loading, SearchBar, convertToArray, t } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { StudentsByGroup } from "./StudentsScreenComponents/StudentsByGroup";
import { StudentsList } from "./StudentsScreenComponents/StudentsList";

import { Flex, View, WhiteSpace } from "@ant-design/react-native";
import { map } from "lodash";
import { Text, TouchableOpacity } from "react-native";
import { useTestSettingsContext, useTestStudentsContext } from "../../providers/SettingsProvider";

import { useStudentSessionsContext } from "../../providers/SettingsProvider";

export const StudentsScreen = ({ navigation }) => {
  const { navigate } = navigation;
  const { userProfile, isEvaluator, language, user } = useContext(AuthenticatedUserContext);

  const {
    studentsData: { showTags, showStudentsGroups, reducedStudentsListForStudentsScreen },
    studentsDispatch,
  } = useTestStudentsContext();

  const {
    studentSessionsData: { currentSession },
  } = useStudentSessionsContext();

  const {
    settings: { tags },
    settingsDispatch,
  } = useTestSettingsContext();

  const setTagsView = (payload) => {
    studentsDispatch({
      type: "setTagView",
      payload,
    });
  };

  const setReducedStudentsListForStudentsScreen = (payload) => {
    studentsDispatch({
      type: "setReducedStudentsListForStudentsScreen",
      payload,
    });
  };

  const setTags = (payload) => {
    settingsDispatch({
      type: "setTags",
      payload,
    });
  };

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const toggleGroupView = () => {
    studentsDispatch({
      type: "toggleGroupView",
    });
  };

  useFocusEffect(
    useCallback(() => {
      setDrawerAdditionalItems([
        {
          label: t("toggle.list"),
          callback: toggleGroupView,
          icon: "ordered-list",
        },
      ]);
      navigation.setOptions({
        title: t("students.header"),
      });
      if (tags.length > 0 && !showTags) {
        setTagsView(true);
      }
    }, [])
  );

  useEffect(() => {
    setTags(currentSession?.tags ?? []);

    navigation.setOptions({
      headerTitle: currentSession?.name,
    });
  }, [currentSession]);

  const searchStudents = (text) => {
    const newStudentsList = convertToArray(currentSession?.students).filter((student) => {
      if (student?.deleted) return false;

      let studentSearchGroup = false;
      if (typeof student.group == "string") {
        studentSearchGroup = student.group.toUpperCase().trim().includes(text.toUpperCase().trim());
      }
      return student.name.toUpperCase().trim().includes(text.toUpperCase().trim()) || studentSearchGroup;
    });
    setReducedStudentsListForStudentsScreen(newStudentsList);
  };

  const resetStudents = () => {
    setReducedStudentsListForStudentsScreen(null);
  };

  const allStudents = useMemo(() => {
    const studentsArray = convertToArray(currentSession.students);

    const currentStudent = find(currentSession.students, (student) => includes(student.key, user.uid));

    let userSubgroup = null;

    if (currentStudent) {
      const splittedGroup = includes(currentStudent?.group, "\\") ? currentStudent?.group.split("\\") : [];
      const subgroup = splittedGroup[splittedGroup.length - 1];

      if (subgroup) userSubgroup = subgroup.trim();
    }

    return orderBy(
      filter(
        studentsArray,
        (student) =>
          !student?.deleted && (userSubgroup ? student.group.includes(userSubgroup) : !student.group.includes("\\"))
      ),
      ["group", "name"]
    );
  }, [userProfile, currentSession]);

  const groupList = useMemo(() => {
    if (!allStudents) return [];
    const byGroup = groupBy(allStudents, "group");
    const groups = keys(byGroup).map((x) => {
      return { id: x, name: x };
    });
    return groups;
  }, [allStudents]);

  if (!language) return <Loading />;

  return (
    <View>
      <WhiteSpace size="sm" />
      <SearchBar onChangeText={searchStudents} resetFunction={resetStudents} />
      {showTags && isEvaluator && (
        <Flex gap={1} style={{ paddingHorizontal: 5 }}>
          {map(currentSession?.tags ?? [], (tag, index) => {
            return (
              <TouchableOpacity
                key={`${tag}-${index}`}
                style={{
                  marginRight: 3,
                  backgroundColor: "#ddd",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 7,
                }}
              >
                <Text>{tag}</Text>
              </TouchableOpacity>
            );
          })}
        </Flex>
      )}

      {!showStudentsGroups && (
        <StudentsList
          reducedStudentsListForStudentsScreen={
            reducedStudentsListForStudentsScreen ? reducedStudentsListForStudentsScreen : allStudents
          }
          allStudents={allStudents}
          groupList={groupList}
          navigate={navigate}
        />
      )}
      {showStudentsGroups && (
        <StudentsByGroup
          data={reducedStudentsListForStudentsScreen ? reducedStudentsListForStudentsScreen : allStudents}
          navigate={navigate}
        />
      )}
    </View>
  );
};
