import { Flex, Icon, Switch, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { httpsCallable } from "firebase/functions";
import React, { useCallback, useContext, useState } from "react";
import {
  AnimatableNumericValue,
  ColorValue,
  Pressable,
  RegisteredStyle,
  StyleSheet,
  Text,
  ViewStyle,
} from "react-native";
import { auth, functions, store, t } from "../../../components";

import { useNavigation } from "@react-navigation/native";

import { AuthenticatedUserContext } from "../../../providers";

import { doc, onSnapshot } from "firebase/firestore";
import { isNull } from "lodash";

import { useLocation } from "../../../hooks/useLocation";
import { useTestSettingsContext, useTestStudentsContext } from "../../../providers/SettingsProvider";
import { updateUserProfile } from "../../../services";

enum WebMenuConstants {
  borderRadius = 10,
  primaryOrange = "#FFA500",
  tagTextColor = "#333",
  tagBackgroundColor = "#CCCCCC",
  webMenuWidth = "65%",
}

interface CustomTagInterface {
  name: string;
  icon: string;
  customStyles?: React.CSSProperties;
  customTextStyles?: React.CSSProperties;
  customIconStyles?: React.CSSProperties;
  callback(): void;
}

const styles = StyleSheet.create({
  webMenuContainer: {
    width: WebMenuConstants.webMenuWidth,
  },
  customTag: {
    backgroundColor: WebMenuConstants.tagBackgroundColor as ColorValue,
    borderRadius: WebMenuConstants.borderRadius as AnimatableNumericValue,
  },
  customTagText: {
    color: WebMenuConstants.tagTextColor as ColorValue,
  },
  customTagIcon: {
    color: WebMenuConstants.tagTextColor as ColorValue,
  },
  textWhite: {
    color: "#ffff",
  },
  addStudents: {
    backgroundColor: "#FFA500",
    borderRadius: WebMenuConstants.borderRadius as AnimatableNumericValue,
  },
  toastText: {
    fontWeight: "bold",
    color: "white",
  },
});

export const CustomTag: React.FC<CustomTagInterface> = ({
  name,
  icon,
  callback,
  customStyles = null,
  customTextStyles = null,
  customIconStyles = null,
}: CustomTagInterface): JSX.Element => {
  return (
    <Pressable onPress={callback}>
      <View
        style={isNull(customStyles) ? styles.customTag : (customStyles as RegisteredStyle<ViewStyle>)}
        testID="add-students-button"
      >
        <WhiteSpace size="sm" />
        <Flex justify="center">
          <WingBlank size="md" />
          <Icon
            name={icon as any}
            style={isNull(customIconStyles) ? styles.customTagIcon : (customIconStyles as RegisteredStyle<ViewStyle>)}
          />
          <WingBlank size="md" />
          <Text
            style={isNull(customTextStyles) ? styles.customTagText : (customTextStyles as RegisteredStyle<ViewStyle>)}
          >
            {name}
          </Text>
          <WingBlank size="md" />
        </Flex>
        <WhiteSpace size="sm" />
      </View>
    </Pressable>
  );
};

interface SessionInterface {
  [keyof: string]: any;
  enabled: boolean;
}

interface StudentInterface {
  group: string;
  email?: string;
  key?: string;
  name: string;
  consentReceived?: boolean;
  noConsent?: boolean;
  noConsentLong?: boolean;
}

export const WebMenu: React.FC<{ currentStudentsList: StudentInterface[]; session: SessionInterface }> = ({
  currentStudentsList,
  session,
}): JSX.Element => {
  // lack of navigation props
  const navigation = useNavigation<any>();
  const { address } = useLocation();

  // lack of context type
  const {
    studentsDispatch,
    studentsData: { showTags, showStudentsGroups },
  } = useTestStudentsContext();
  const {
    settings: { tags },
  } = useTestSettingsContext();

  // lack of context type
  const { userProfile, setUserProfile } = useContext(AuthenticatedUserContext) as any;

  const [enableInProgress, setEnableInProgress] = useState(false);
  const [sessionStats, setSessionStats] = useState(null);

  const handleToggleTagView = (): void => studentsDispatch({ type: "toggleTagView" });
  const handleToggleGroupView = (): void => studentsDispatch({ type: "toggleGroupView" });

  const handleGoToAddStudentsScreen = (): void =>
    navigation.navigate("AddStudents", { title: t("sessions.add.addStudents"), currentStudentsList, session });

  const handleDisplayStatistics = () =>
    handleUserProfileUpdate("showSessionStats", !userProfile.profile.showSessionStats);

  const handleUserProfileUpdate = (field, value = false) => {
    const newUserProfile = { ...userProfile };
    newUserProfile.profile[field] = value;

    updateUserProfile(newUserProfile.profile)
      .then(() => {
        setUserProfile(newUserProfile);

        Toast.success({
          content: t("saveSuccess"),
          duration: 1,
        });
      })
      .catch((err) => {
        Toast.fail({
          content: t("error"),
          duration: 1,
        });
      });
  };

  const enableSessionStats = useCallback((statEntry) => {
    if (!session && !statEntry) return;

    onSnapshot(doc(store, `sessionStats/${session.id}/entries/${statEntry}`), (sE) => {
      if (!sE.data()) return;
      setSessionStats(sE.data());
    });
  }, []);

  const toggleSession = useCallback(({ location }) => {
    setEnableInProgress(true);
    const toggleSession = httpsCallable(functions, "toggleSession");

    toggleSession({
      sessionId: session.id,
      requestedUserId: auth.currentUser.uid,
      tags: tags,
      location,
    })
      .then((response: any) => {
        if (response.data.status === "enabled") {
          Toast.info({
            content: t("sessions.enabled"),
            duration: 3,
          });

          enableSessionStats(response.data.statEntry);
        } else {
          Toast.info({
            content: (
              <>
                <Text style={styles.toastText}>{t("sessions.disabled")}</Text>
                {response.data.stats && (
                  <>
                    <Text style={styles.toastText}>{t("sessions.stats.title")}</Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.overall")}: <Text>{response.data.stats.all}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.group")}: <Text>{response.data.stats.group}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.self")}: <Text>{response.data.stats.self}</Text>
                    </Text>
                    <Text style={styles.toastText}>
                      {t("sessions.stats.evaluators")}: <Text>{response.data.stats.evaluators}</Text>
                    </Text>
                  </>
                )}
              </>
            ),
            duration: 3,
          });
        }
        setEnableInProgress(false);
        setSessionStats(null);
      })
      .catch((err) => {
        Toast.info({
          content: t("error"),
          duration: 1,
        });

        setEnableInProgress(false);
      });
  }, []);

  return (
    <View testID="web-menu">
      <Flex justify="end" wrap="wrap" style={styles.webMenuContainer}>
        <CustomTag
          name={t("sessions.add.addStudents")}
          icon="user-add"
          callback={handleGoToAddStudentsScreen}
          customStyles={styles.addStudents as React.CSSProperties}
          customTextStyles={styles.textWhite as React.CSSProperties}
          customIconStyles={styles.textWhite as React.CSSProperties}
        />

        <WingBlank size="md">
          <View testID="toggle-session-switch-container">
            <Flex>
              <Text>{t("sessions.toggleSession")}</Text>
              <WingBlank size="sm" />
              <Switch
                onChange={() => toggleSession({ location: { country: address?.[0].country ?? "" } })}
                checked={session?.enabled}
                loading={enableInProgress}
              />
            </Flex>
          </View>
        </WingBlank>

        <WingBlank size="md">
          <Flex>
            <Text>{t("displayStatistics")}</Text>
            <WingBlank size="sm" />
            <Switch
              onChange={handleDisplayStatistics}
              checked={userProfile?.profile?.showSessionStats || false}
              disabled={!session?.enabled}
            />
          </Flex>
        </WingBlank>

        <WingBlank size="md">
          <Flex>
            <Text>{t("toggle.tag")}</Text>
            <WingBlank size="sm" />
            <Switch onChange={handleToggleTagView} checked={showTags} />
          </Flex>
        </WingBlank>

        <WingBlank size="md">
          <Flex>
            <Text>{t("toggle.list")}</Text>
            <WingBlank size="sm" />
            <Switch onChange={handleToggleGroupView} checked={showStudentsGroups} />
          </Flex>
        </WingBlank>
      </Flex>
    </View>
  );
};

export const WebMenuEvaluations: React.FC<{ callback(): void }> = ({ callback }) => {
  return (
    <Flex justify="end" style={styles.webMenuContainer}>
      <CustomTag callback={callback} name={t("toggle.list")} icon="monitor" />
      <WingBlank size="sm" />
    </Flex>
  );
};
