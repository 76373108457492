import React, { useContext, useEffect } from "react";

import { Flex, Icon, View, WingBlank } from "@ant-design/react-native";

import UserAvatar from "react-native-user-avatar";

import { Platform, Text } from "react-native";

import { DrawerContentScrollView, DrawerItem, DrawerItemList } from "@react-navigation/drawer";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { t } from "i18n-js";
import { isNil, map } from "lodash";
import { AuthenticatedUserContext } from "../providers";

import * as WebBrowser from "expo-web-browser";

import { signOut } from "firebase/auth";
import { useTestSettingsContext } from "../providers/SettingsProvider";
import { auth } from "./Firebase";

const CustomDrawer = (props) => {
  const { userProfile, setUser, user } = useContext(AuthenticatedUserContext);
  const {
    settings: { drawerAdditionalItems },
  } = useTestSettingsContext();
  const { navigation } = props;

  const { routes, index } = navigation.getState();

  const handleSignOut = async () => {
    try {
      await signOut(auth).then(() => setUser(null));
    } catch (err) {
      console.warn(err);
    }
  };

  const currentRouteName = routes[index].name;

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("changelogWasViewed");

      //remove before deployment
      // await AsyncStorage.removeItem("changelogWasViewed");

      if (isNil(value) || value === "false") {
        return navigation.navigate("changelog");
      }
      return;
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <View style={{ height: "100%" }}>
      <Flex justify="start" align={Platform.OS === "web" ? "center" : "end"} style={{ width: "100%", height: "20%" }}>
        <Flex justify="start">
          <WingBlank size="md" />
          <UserAvatar name={userProfile?.profile.name ?? ""} size={50} bgColor="orange" />
          <WingBlank size="sm" />
          <Text style={{ fontWeight: "bold" }}>{userProfile?.profile.name ?? ""}</Text>
        </Flex>
      </Flex>

      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {map(drawerAdditionalItems, ({ label, icon, callback }, index) => (
          <DrawerItem
            label={() => <Text>{label}</Text>}
            icon={() => <Icon name={icon} />}
            onPress={() => {
              callback();
              navigation.closeDrawer();
            }}
            key={index}
          />
        ))}

        <DrawerItemList {...props} />
        <DrawerItem
          label={() => <Text>{t("settings.terms")}</Text>}
          icon={() => <Icon name="file-text" />}
          style={{
            marginTop: "auto",
          }}
          onPress={() => WebBrowser.openBrowserAsync("https://pentabilities.com/terms")}
        />

        {currentRouteName !== "settings" && (
          <DrawerItem
            label={() => <Text>{t("settings.title")}</Text>}
            icon={() => <Icon name="setting" />}
            onPress={() => navigation.jumpTo("settings")}
          />
        )}
        {currentRouteName === "settings" && (
          <DrawerItem
            label={() => <Text>{t("settings.signOut")}</Text>}
            icon={() => <Icon name="logout" />}
            onPress={handleSignOut}
          />
        )}
      </DrawerContentScrollView>
    </View>
  );
};

export default CustomDrawer;
