import { useCallback, useContext, useEffect, useState } from "react";
import { AuthenticatedUserContext } from "../../providers";

import { FlatList, Pressable, Text } from "react-native";

import { Card, Flex, Icon, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { isEmpty } from "lodash";
import { Loading, store } from "../../components";
import { t } from "../../components/Localize";
import { useStudentSessionsContext, useTestSettingsContext } from "../../providers/SettingsProvider";
import { useFocusEffect } from "@react-navigation/native";

const StudentSessionsScreen = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(true);

  const authContext = useContext(AuthenticatedUserContext);

  const { settingsDispatch } = useTestSettingsContext();

  const {
    studentSessionsData: { studentSessions, currentSession },
    studentSessionsDispatch,
  } = useStudentSessionsContext();

  const handleSessionCardPress = (payload, additionalOptions = {}) => {
    studentSessionsDispatch({
      type: "setCurrentSession",
      payload,
    });

    navigation.navigate("Students", { headerTitle: payload?.name, ...additionalOptions });
  };
  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const renderItem = useCallback(({ item }) => {
    return (
      <View key={item.key}>
        <WingBlank size="lg">
          <Card>
            <Card.Header title={<Text testID="session-name">{item.name}</Text>} />
            <Card.Footer
              content={
                <Pressable onPress={() => handleSessionCardPress(item)}>
                  <WhiteSpace />
                  <Flex justify="between">
                    <Text>{t("sessions.add.students")}</Text>
                    <Icon name="double-right" size="sm" testID="session-card-go-to-students" />
                  </Flex>
                </Pressable>
              }
            />
          </Card>
        </WingBlank>
        <WhiteSpace size="sm" />
      </View>
    );
  });

  useFocusEffect(
    useCallback(() => {
      setDrawerAdditionalItems([]);
    }, [])
  );

  useEffect(() => {
    try {
      const q = query(
        collection(store, "sessions"),
        where("studentsKeys", "array-contains", authContext.user.uid),
        where("enabled", "==", true)
      );

      const unsubscribe = onSnapshot(q, (docs) => {
        const data = [];

        docs.forEach((doc) => {
          if (doc.data()?.isDeleted !== true) data.push({ ...doc.data(), key: doc.id });
        });

        if (data.length === 0) {
          navigation.navigate("StudentSessions");
        }

        studentSessionsDispatch({
          type: "setStudentSessions",
          payload: data,
        });
      });

      return () => {
        unsubscribe();
      };
    } finally {
      setIsLoading(false);
    }
  }, [authContext, currentSession]);

  useEffect(() => {
    if (studentSessions.length === 1 && isEmpty(currentSession)) handleSessionCardPress(studentSessions[0]);
  }, [studentSessions, currentSession]);

  if (isLoading)
    return (
      <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
        <Loading />
      </Flex>
    );

  return (
    <FlatList
      data={studentSessions}
      renderItem={renderItem}
      ListEmptyComponent={
        <Flex style={{ width: "100%", minHeight: 300 }} justify="center" align="center">
          <Flex justify="center" align="center" gap={10}>
            <Text>{t("noSessions")}</Text>
          </Flex>
        </Flex>
      }
      initialNumToRender={10}
      contentContainerStyle={{ paddingBottom: 250 }}
      keyExtractor={(v) => v?.key ?? v?.id}
    />
  );
};

export default StudentSessionsScreen;
