import React, { memo, useCallback } from "react";
import { Platform } from "react-native";

import { t } from "i18n-js";

import { useTestSettingsContext } from "../../providers/SettingsProvider";

import { View } from "@ant-design/react-native";

import { useFocusEffect } from "@react-navigation/native";

import { SessionFabAdd } from "./SessionListScreenComponents/SessionFabAdd";
import { SessionList } from "./SessionListScreenComponents/SessionList";

const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

const withSettings = (SessionListScreen) => {
  const Memoized = memo(SessionListScreen);

  return ({ navigation }) => {
    const { settingsDispatch } = useTestSettingsContext();
    return <Memoized settingsDispatch={settingsDispatch} navigation={navigation} />;
  };
};

export const SessionListScreen = withSettings(({ settingsDispatch, navigation }) => {
  const navigateToAddSessionScreen = () => {
    navigation.navigate("SessionAdd", { headerLeft: () => false });
  };

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        title: t("sessions.title"),
      });

      settingsDispatch({
        type: "setDrawerAdditionalItems",
        payload: [],
      });
    }, [])
  );

  return (
    <View style={{ width: "100%", height: "100%" }} testID="sessions-list">
      <SessionList navigation={navigation} />
      {Platform.OS !== "web" && <SessionFabAdd navigateToAddSessionScreen={navigateToAddSessionScreen} />}
    </View>
  );
});
