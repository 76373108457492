import React, { memo, useEffect, useState } from 'react';

import { Button, Flex, Icon, Modal, View, WhiteSpace, WingBlank } from '@ant-design/react-native';
import CustomizedInputItem from '../../../components/Inputs/CustomizedInputItem';

import { Text } from 'react-native';

import { t } from 'i18n-js';

export const SessionModal = memo(({ value, title, visible, onClose, onSave }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      transparent={true}
      maskClosable={true}
      visible={visible}
      onClose={handleCloseModal}
      animationType={'slide'}
      title={title}
    >
      <WhiteSpace size="md" />
      <CustomizedInputItem value={inputValue} onChangeText={(text) => setInputValue(text)} />
      <WhiteSpace size="md" />
      <Flex justify="end">
        <Button type="ghost" onPress={handleCloseModal}>
          {t('cancel')}
        </Button>
        <WingBlank size="sm" />
        <Button
          type="primary"
          style={{ color: 'white' }}
          onPress={() => {
            onSave(inputValue);
            handleCloseModal();
          }}
        >
          <Flex justify="center">
            <Icon style={{ color: '#fff' }} name="save" testID="save-icon" />
            <WingBlank size="sm" />
            <Text style={{ color: '#fff' }}>{t('save')}</Text>
          </Flex>
        </Button>
      </Flex>
    </Modal>
  );
});
