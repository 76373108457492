import React, { useEffect, useState } from "react";

import { Platform } from "react-native";
import { Loading, SearchBar } from "../../components";
import { DimensionsCardsList } from "../../components/behavioursShared/DimensionsCardsList";
import { SaveButtonsStack } from "./SessionUserEvaluation/SaveButton";

import { ActivityIndicator, Flex, View, WhiteSpace } from "@ant-design/react-native";

import { WebMenuEvaluations } from "./SessionStudentsScreenComponents/WebMenu";

import { t } from "../../components";
import { useDimensionsContext } from "../../providers/SettingsProvider";

export const SessionUserEvaluation = ({ navigation, route }) => {
  const [activeSections, setActiveSections] = useState([0]);
  const [loading, setLoading] = useState(true);

  const [isAllCollapsed, setIsAllCollapsed] = useState(true);

  const [isRequestPending, setIsRequestPending] = useState(false);

  const {
    dimensionsData: { focusedDimensions },
  } = useDimensionsContext();

  const webStyles =
    Platform.OS === "web"
      ? {
          flex: 1,
        }
      : {};

  const toggleShowEvidences = () => {
    setIsAllCollapsed((prev) => {
      if (prev) setActiveSections([]);
      else setActiveSections([0, 1]);

      return !prev;
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <View style={{ height: "98%", ...webStyles }}>
      {Platform.OS === "web" ? (
        <Flex>
          <SearchBar action={"searchDimensions"} />
          <WebMenuEvaluations callback={toggleShowEvidences} />
        </Flex>
      ) : (
        <SearchBar action={"searchDimensions"} />
      )}

      <ActivityIndicator animating={isRequestPending} toast={true} size="large" text={t("loading")} />

      <WhiteSpace size="md" />

      {!loading ? (
        <DimensionsCardsList
          route={route}
          isAllCollapsed={isAllCollapsed}
          setIsAllCollapsed={setIsAllCollapsed}
          navigation={navigation}
          selectedBehaviours={focusedDimensions}
          activeSections={activeSections}
          setActiveSections={setActiveSections}
        />
      ) : (
        <Loading />
      )}

      <SaveButtonsStack route={route} setIsLoading={setIsRequestPending} isLoading={isRequestPending} />
    </View>
  );
};
