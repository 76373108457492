import React, { memo, useCallback, useState } from "react";

import { t } from "i18n-js";
import { findIndex, keys, orderBy } from "lodash";

import { FlatList, Platform, RefreshControl } from "react-native";

import { Flex, List, Modal, View, WingBlank } from "@ant-design/react-native";
import { useFocusEffect } from "@react-navigation/native";
import { getCategoryColor } from "../../components";
import { FeedbackModal } from "../../components/Feedback/Modal/FeedbackModal";
import { useDimensionsContext, useTestSessionsContext } from "../../providers/SettingsProvider";
import { updateSession } from "../../services";
import { BehaviourItem } from "./SessionListScreenComponents/BehaviourItem";

export const SessionCategorySelectModal = memo(
  ({ session, modalOpen, onClose, selectedBehaviours, setSelectedBehaviours }) => {
    const {
      dimensionsData: { dimensions, withTranslation },
    } = useDimensionsContext();

    const [isWarningAlertOpen, setIsWarningAlertOpen] = useState(false);
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

    const {
      sessionsData: { sessions },
      sessionsDispatch,
    } = useTestSessionsContext();

    // pending | success | error
    const [status, setStatus] = useState("pending");

    const [loading, setLoading] = useState(true);

    const [localSelectedBehaviours, setLocalSelectedBehaviours] = useState(selectedBehaviours);


    const setSessions = (payload) => {
      sessionsDispatch({
        type: "setSessions",
        payload,
      });
    };

    const closeModal = () => {
      closeWarningModal();
      onClose();
    };

    const getFlattenedDimensions = () => {
      const flattenedDimensions = [];

      keys(dimensions).forEach((category) => {
        keys(dimensions[category].dimensions).forEach((behaviour) => {
          if (behaviour !== "otherComments") {
            flattenedDimensions.push({ behaviour, category });
          }
        });
      });

      return flattenedDimensions;
    };

    const updateSelectedBehaviours = (behaviour) => {
      let newSelectedBehaviours = [...localSelectedBehaviours];
      const index = findIndex(localSelectedBehaviours, (b) => b.behaviour == behaviour.behaviour);

      if (index === -1) {
        newSelectedBehaviours.push(behaviour);
      } else {
        newSelectedBehaviours.splice(index, 1);
      }

      if (!isWarningAlertOpen && session.enabled) setIsWarningAlertOpen(true);

      setLocalSelectedBehaviours(newSelectedBehaviours);
    };

    const saveSelectedBehaviours = () => {
      try {
        handleSelectedBehavioursSave(localSelectedBehaviours);
        setSelectedBehaviours(localSelectedBehaviours);
        closeModal();
      } catch (err) {
        throw Error("While saving target behaviours");
      }
    };

    const handleSelectedBehavioursSave = async (value) => {
      const { id, ...newSession } = session;
      newSession["selectedBehaviours"] = value;
      try {
        setIsFeedbackModalVisible(true);
        await updateSession(id, newSession, sessions);
        setStatus("success");
      } catch (err) {
        setStatus("error");
      }
    };

    const closeWarningModal = () => setIsWarningAlertOpen(false);

    const renderItem = useCallback(
      ({ item }, index) => {
        const isChecked = findIndex(localSelectedBehaviours, (b) => b.behaviour == item.behaviour) !== -1;

        return (
          <List.Item
            key={index}
            onPress={() => updateSelectedBehaviours(item)}
            thumb={
              <View
                style={{
                  height: "100%",
                  width: isChecked ? 20 : 0,
                  backgroundColor: getCategoryColor(item.category, keys(dimensions)),
                }}
              />
            }
          >
            <Flex>
              <WingBlank size="sm" />
              <BehaviourItem
                behaviour={item}
                dimensions={dimensions}
                withTranslation={withTranslation}
                key={`behaviour_item_${index}`}
              />
            </Flex>
          </List.Item>
        );
      },
      [dimensions, selectedBehaviours, localSelectedBehaviours]
    );

    const webStyles =
      Platform.OS === "web"
        ? {
            width: "50vw",
          }
        : {};

    useFocusEffect(
      useCallback(() => {
        if (loading) {
          setLoading(false);
        }
      }, [selectedBehaviours, loading])
    );

    return (
      <>
        <FeedbackModal
          visible={isFeedbackModalVisible}
          setModalVisible={setIsFeedbackModalVisible}
          pendingMessage={t("updatesHandling.saving")}
          successMessage={t("updatesHandling.updated")}
          errorMessage={t("updatesHandling.generalError")}
          status={status}
        />
        <Modal
          visible={modalOpen}
          style={webStyles}
          onClose={closeModal}
          footer={[
            { text: t("cancel"), onPress: closeModal },
            { text: t("save"), onPress: saveSelectedBehaviours },
          ]}
          maskClosable={true}
          transparent={true}
          animationType={"slide"}
          title={t("sessions.selectBehaviours.title")}
        >
          <FlatList
            style={{
              height: Platform.OS === "web" ? "70vh" : "80%",
              padding: 20,
            }}
            data={getFlattenedDimensions()}
            refreshing={loading}
            refreshControl={<RefreshControl refreshing={loading} onRefresh={() => getFlattenedDimensions()} />}
            renderItem={renderItem}
          />
        </Modal>
      </>
    );
  }
);
