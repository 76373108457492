import { Flex, View } from "@ant-design/react-native";
import { t } from "i18n-js";
import React, { useContext } from "react";
import { Text } from "react-native";
import { AuthenticatedUserContext } from "../../../providers";

export const BehaviourItem = ({ behaviour, dimensions, withTranslation }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);

  return (
    <Flex>
      <View>
        <Text style={{ fontWeight: "bold" }}>
          {!withTranslation && t(`evaluation.${behaviour.category}.dimensions.${behaviour.behaviour}`)}
          {withTranslation &&
            dimensions[behaviour.category].dimensions[behaviour.behaviour][
              `label_${userProfile.profile.language || "en"}`
            ]}
        </Text>
        <Text>
          {!withTranslation && t(`evaluation.${behaviour.category}.title`)}
          {withTranslation && dimensions[behaviour.category][`title_${userProfile.profile.language || "en"}`]}
        </Text>
      </View>
    </Flex>
  );
};
