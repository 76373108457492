import { auth } from "../components";

import { includes, isEmpty, isNil, isUndefined, map } from "lodash";
import { httpsCallable } from "firebase/functions";

import { functions } from "../components";

const studentRoles = ["student", "participant"];
const evaluatorRoles = ["admin", "administrator", "tutor", "evaluator", "eval", "teacher"];

const saveEvidence = (student, destination, data, tenantName, session, role, currentUserId) => {
  return new Promise((resolve, reject) => {
    if (isNil(role)) {
      reject("unrecognized user role");
    }

    if (isNil(currentUserId)) {
      reject("lack of current user id");
    }

    if (isUndefined(tenantName) || isNil(session) || isEmpty(session) || (isNil(session.id) && isNil(session.key))) {
      reject("some properties are missing ");
    }

    const newEvidenceFirestore = {
      eid: auth.currentUser.uid,
      group: student.group || "",
      uid: student.key.replace("sub_", "").replace("self_", ""),
      category: destination.category,
      behaviour: destination.evidence,
      type: student.key.includes("self") ? "self" : student.key.includes("sub") ? "co" : "teacher",
      ...data,
    };

    if (includes(studentRoles, role.toLowerCase()))
      if (student.key === currentUserId) newEvidenceFirestore["type"] = "self";
      else newEvidenceFirestore["type"] = "co";

    if (includes(evaluatorRoles, role.toLowerCase())) newEvidenceFirestore["type"] = "teacher";
    if (!newEvidenceFirestore["type"]) reject("lack of evaluation type");

    if (
      newEvidenceFirestore.tags.length === 0 ||
      includes(
        map(newEvidenceFirestore.tags, (tag) => (includes(tag, "undefined") ? false : true)),
        false
      )
    ) {
      throw new Error("Value in tags is undefined or is empty");
    }

    resolve(
      httpsCallable(
        functions,
        "addEvaluation"
      )({
        token: "cf69bb6d-953c-49d5-b43c-c081346b32a2",
        evidence: newEvidenceFirestore,
        studentKey: student.key,
        student,
        tenant: tenantName,
        sessionId: session?.id ?? session?.key,
        statEntryId: session.statEntry,
      })
    );
  });
};

export { saveEvidence };
